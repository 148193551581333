<template>

    <div class="page-container">
        
        <Header @sendCmd="sendCmd" :headerConfig="headerConfig" ></Header>
        <Python ref="page" />
        
    </div>

</template>

<script>
import { setHeaderConfig } from '@/utils/common';
import Header from '../header/Header';
import Python from './Python'

export default {
    name: 'graphic-python-page',
    data: function() {
        return {
            headerConfig: {}
        }
    },
    created() {
    },
    mounted() {
        let pid = ''
        if (this.$route.query.pid) {
            pid = this.$route.query.pid
        }

        this.headerConfig = setHeaderConfig({
            goBack: true,
            projName: true,
            fullscreen: true,
            save: true,
            release: true,
            data: true,
            code: true,
        })

        // 加载数据
        this.$refs.page.loadPage(pid)
    },
    activated() {
    },
    deactivated() {
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
    },
    components: {
        Header,
        Python
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
