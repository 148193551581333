<template>

    <div class="data-area" v-if="ide.area.isShowDataArea">
        <div class="screen-mask"></div>
        <div class="container">
            <div class="container-item" v-for="data in dataAreaData" v-bind:key="data.name">
                <div class="actor-name">{{ data.name }}</div>
                <!-- <div class="actor-name" @click.stop="show()">我的角色</div> -->
                <div class="actor-data">
                    <!-- 变量：自动折行 -->
                    <div class="var-data">
                        <div class="var-data-item" v-for="variable in data.variables" v-bind:key="variable.name">
                            <div class="name">{{ variable.name }}</div>
                            <div class="value">{{ formatFload(variable.value) }}</div>
                        </div>
                    </div>
                    <!-- 列表：每行一个 -->
                    <div class="list-data" v-for="list in data.lists" v-bind:key="list.name">
                        <div class="list-name">
                            <div class="name">{{ list.name }}</div>
                            <div class="length">长度：{{ list.value.length }}</div>
                        </div>
                        <div class="list-data-value">
                            <div class="value-item" v-for="(v, index) in list.value" v-bind:key="index">
                                <div class="index">{{ index + 1 }}</div>
                                <div class="value">{{ formatFload(v) }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'dataArea',
        props: [
        ],
        data: function () {
            return {
                img: {
                    logo: '@/assets/images/common/logo_white.png',
                },
            }
        },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
            ]),
        },
        mounted() {
            window.setDataAreaData = (actorId, varId, varData) => {
                this.setDataAreaData(actorId, varId, varData);
            };
        },
        created() {
        },
        activated() {
        },
        watch: {
        },
        methods: {
            setDataAreaData(actorId, varId, varData) {
                this.$store.commit('web/SetDataAreaData', {
                    actorId: actorId,
                    varId: varId,
                    varData: varData
                });
            },
            formatFload(v) {
                if (!Number.isNaN(v)) {
                    return Number.parseFloat(v).toFixed(2);
                }
                return v;
            }
        }
    }
</script>

<style lang="scss" scoped>
.data-area {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #000;

    .screen-mask {
        position: absolute;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: rgba(0, 0, 0, 0.9);
        //background: #000;
        //background: rgba(17, 17, 17, 0.9);
        background: rgba(50, 50, 50, 0.95);
        opacity: 1;
        visibility: visible;
        //cursor: pointer;
    }

    .container {
        position: absolute;
        z-index: 2001;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;

        .container-item {
            display: flex;
            width: 100%;
            padding: 15px 20px 0 0;

            .actor-name {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                min-width: 80px;
                height: 30px;
                font-size: 14px;
                color: #e6e6e6;
            }

            .actor-data {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .var-data {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 100%;

                    .var-data-item {
                        display: flex;
                        font-size: 12px;
                        margin: 0 15px 15px 0;
                        font-weight: bold;

                        .name {
                            min-width: 30px;
                            color: #fff;
                            letter-spacing: .5px;
                            padding: 5px 8px;
                            // background-color: #4c97ff;
                            background-color: #ff8c1a;
                            // border-top-left-radius: 4px;
                            // border-bottom-left-radius: 4px;
                        }

                        .value {
                            color: #555;
                            // padding: 5px 12px;
                            padding: 5px 0;
                            width: 60px;
                            text-align: center;
                            background-color: #fff;
                            // border-top-right-radius: 4px;
                            // border-bottom-right-radius: 4px;
                        }
                    }
                }

                .list-data {
                    display: flex;
                    width: 100%;
                    font-size: 12px;
                    margin-bottom: 15px;
                    .list-name {
                        display: flex;
                        flex-direction: column;
                        // min-width: 80px;
                        font-weight: bold;
                        .name {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            white-space: nowrap;
                            // min-width: 55px;
                            height: 28px;
                            padding: 0 8px;
                            background-color: #ff661a;
                            // border-top-left-radius: 4px;
                            // border-bottom-left-radius: 4px;
                            color: #fff;
                            letter-spacing: .5px;
                        }
                        .length {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            white-space: nowrap;
                            height: 28px;
                            background-color: #fff;
                            color: #333;
                        }
                    }
                    .list-data-value {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        max-height: 120px;
                        overflow-y: auto;
                        .value-item {
                            display: flex;
                            flex-direction: column;
                            font-weight: bold;
                            width: 60px;
                            .index {
                                color: rgb(244, 52, 52);
                                padding: 5px 12px;
                                // border: 1px solid #ccc;
                                border-left: 1px solid #ccc;
                                border-bottom: 1px solid #ccc;
                                background-color: #fff;
                                text-align: center;
                            }
                            .value {
                                padding: 5px 12px;
                                // border: 1px solid #ccc;
                                border-left: 1px solid #ccc;
                                border-bottom: 1px solid #ccc;
                                background-color: #fff;
                                color: #333;
                            }
                        }
                    }
                }
            }
        }

    }
}
</style>
