<template>
    <div class="bottom-area">
        <div
            class="bottom-screen-mask"
            v-if="(current == 'demo' && !isShowDemoCode) || isRunning"
        ></div>
        <div class="actor-area" ref="actorArea">
            <div class="btn-add-actor" @click="openMediaLibWindow('all')">
                <img src="@/assets/images/ide/add_actor.png" />
            </div>
            <div class="backdrop"
                v-bind:class="{ selected: 'backdrop' == curActor.id }"
                @click="selectSprite('backdrop')"
            >
                <div class="bg">
                    <img
                        :src="actorAreaData.backdrop.src"
                        v-if="actorAreaData.backdrop.src != ''"
                    />
                </div>
                <div class="name">背景</div>
                <div class="border" v-if="'backdrop' == curActor.id"></div>
                <div class="setting" v-show="'backdrop' == curActor.id">
                    <img 
                        src="@/assets/images/ide/setting.png"
                        @click="openConfigureWindow(true)"
                    />
                </div>
            </div>
            <div id="actors" class="actors">
                <draggable
                    :list="actorAreaData.actors"
                    @change="actorsChange"
                    class="draggable"
                >
                    <div class="actor"
                        v-for="actor in actorAreaData.actors"
                        v-bind:key="actor.id"
                        v-bind:class="{ selected: actor.id == curActor.id }"
                        @click="selectSprite(actor.id)"
                    >
                        <div class="avatar">
                            <img :src="actor.src" />
                        </div>
                        <div class="name ellipsis">{{ actor.name }}</div>
                        <div class="border" v-if="actor.id == curActor.id"></div>
                        <div class="setting"
                            v-show="actor.id == curActor.id"
                            @click="openConfigureWindow(false)"
                        >
                            <img src="@/assets/images/ide/setting.png" />
                        </div>
                        <div class="close"
                            v-show="actor.id == curActor.id"
                            @click.stop="showDeleteDialog(actor.id)"
                        >
                            <img src="@/assets/images/ide/close_white.png" />
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import CoreData from '@/libs/runtime/coreData.js';

import { mapGetters } from "vuex";
import { getImgUrl2 } from "@/utils/util";

export default {
    name: "Bottom",
    props: [],
    data: function () {
        return {
            // 当前准备删除的Actor
            curDeleteActorId: "",
        };
    },
    computed: {
        ...mapGetters([
            "current",
            "curActor",
            "actorAreaData",
            "isShowDemoCode",
            "isRunning",
        ]),
    },
    created() {},
    mounted() {
        let actorsContainer = document.getElementById("actors");
        actorsContainer.addEventListener("wheel", (event) => {
            event.preventDefault();
            actorsContainer.scrollLeft += event.deltaY;
        });
    },
    activated() {},
    watch: {},
    methods: {
        openMediaLibWindow(type, showCostume = 'false') {
            this.$store.commit("web/ShowWindow", {
                name: "MEDIA_LIB",
                isShow: true,
                type: type,
                showCostume: showCostume
            });
        },
        openConfigureWindow(isBackdrop) {
            if (isBackdrop) {
                let backdrop = CoreData.getCurBackdrop();
                this.$store.commit("web/SetPropData", {
                    isBackdrop: true,
                    meta: backdrop.meta,
                    costumes: backdrop.costumes,
                    sounds: backdrop.sounds,
                });

                let costume = CoreData.getCurCostume("backdrop");
                if (costume) {
                    this.$store.commit("web/SetActorAreaData", {
                        id: "backdrop",
                        src: getImgUrl2(costume.id),
                    });
                } else {
                    this.$store.commit("web/SetActorAreaData", {
                        id: "backdrop",
                        src: "",
                    });
                }
            } else {
                let actor = CoreData.getCurActor(this.curActor.id);
                if (actor) {
                    this.$store.commit("web/SetPropData", {
                        isBackdrop: false,
                        meta: actor.meta,
                        costumes: actor.costumes,
                        sounds: actor.sounds,
                    });
                }

                let costume = CoreData.getCurCostume(this.curActor.id);
                if (costume) {
                    this.$store.commit("web/SetActorAreaData", {
                        id: this.curActor.id,
                        src: getImgUrl2(costume.id),
                    });
                }
            }

            // 设置当前正在设置的状态
            this.$store.commit("web/SetIsShowSetting", true);

            // 打开窗口
            this.$store.commit("web/ShowWindow", {
                name: "CONFIG",
                isShow: true,
            });
        },
        selectSprite(id) {
            this.$store.dispatch("web/SelectSprite2", id);
        },
        showDeleteDialog(id) {
            let that = this;

            this.curDeleteActorId = id;
            this.$store.commit("web/ShowModalDlg", {
                title: "确定要删除这个角色么？",
                content:
                    "角色 " +
                    this.curActor.name +
                    " 的造型及对应代码将被删除",
                highlight: this.curActor.name,
                okCallback: () => {
                    if (that.curDeleteActorId != "") {
                        that.$store.dispatch(
                            "web/DeleteSprite",
                            that.curDeleteActorId
                        );
                        that.curDeleteActorId = "";
                    }
                },
                cancelCallback: null,
            });
        },
        actorsChange(e) {
            if (e.moved) {
                // 更新角色区index
                this.$store.commit('web/UpdateActorDataIndex');
                // 使用新index更新展示区中的角色
                window.stageInstance.updateSpriteIndex(this.actorAreaData.actors);
            }
        }
    },
    components: {
        draggable,
    },
};
</script>

<style lang="scss" scoped>
.bottom-area {
    height: 110px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: relative;

    .bottom-screen-mask {
        position: absolute;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: rgba(50, 50, 50, 0.95);
        background: rgba(50, 50, 50, 1);
        opacity: 1;
        visibility: visible;
    }

    .actor-area {
        background-color: #525357;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding: 7px 7px;

        .btn-add-actor {
            width: 80px;
            height: 80px;
            /*border: 3px solid #fff;*/
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: #8a8a8a;
            border-radius: 8px;
            margin: 8px 8px;
            //border: 2px solid #525357;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .btn-add-actor:hover {
            cursor: pointer;
        }

        .backdrop {
            position: relative;
            width: 80px;
            height: 80px;
            //border: 2px dashed #8a8a8a;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-radius: 8px;
            margin: 8px 8px;
            cursor: pointer;

            //background: #ffffff;
            //background-image:
            //        linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0),
            //        linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0);
            //background-position: 0 0, 10px 10px;
            //background-size: 20px;

            background: #ffffff;
            background-image: linear-gradient(
                    45deg,
                    #efefef 25%,
                    transparent 0,
                    transparent 75%,
                    #efefef 0
                ),
                linear-gradient(
                    45deg,
                    #efefef 25%,
                    transparent 0,
                    transparent 75%,
                    #efefef 0
                );
            background-position: 0 0, 15px 15px;
            background-size: 30px 30px;

            .bg {
                height: 56px;
                width: 80px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                img {
                    height: 100%;
                    width: 100%;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    object-fit: cover;
                }
            }
            .name {
                height: 24px;
                width: 100%;
                //background-color: #bcb7ac;
                background-color: #383838;
                font-size: 13px;
                padding-top: 2px;
                color: #fff;
                text-align: center;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .border {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 3px solid #4c97ff;
                border-radius: 8px;
            }

            &.selected {
                //background-color: #bcb7ac;
                background-color: #ffffff;
                //border: 3px solid #4c97ff;
                //border-radius: 12px;
                //border: 2px dashed #fff;
                //width: 81px;
                //height: 81px;
                //.name {
                //  top: 53px;
                //}
            }
        }

        .draggable {
            display: flex;
        }

        .actors {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            overflow-x: scroll;
        
            .actor {
                position: relative;
                width: 80px;
                height: 80px;
                //border: 2px dashed #8a8a8a;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                align-items: center;
                background-color: #8a8a8a;
                border-radius: 8px;
                margin: 8px 8px;
                cursor: pointer;

                .avatar {
                    height: 56px;
                    width: 56px;
                    padding: 5px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .name {
                    height: 24px;
                    width: 100%;
                    //background-color: #bcb7ac;
                    background-color: #383838;
                    font-size: 12px;
                    padding-top: 2px;
                    color: #fff;
                    text-align: center;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                .border {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border: 3px solid #4c97ff;
                    border-radius: 8px;
                }

                &.selected {
                    background-color: #bcb7ac;
                    //border: 3px solid #4c97ff;
                    //border-radius: 12px;
                    //border: 2px dashed #fff;
                    //border: 2px solid #383838;
                    //width: 76px;
                    //height: 76px;
                    //.name {
                    //  top: 53px;
                    //}
                }
            }
        }
        .actors::-webkit-scrollbar {
            display: none
        }

        .setting {
            position: absolute;
            top: 5px;
            left: 5px;
            height: 28px;
            width: 28px;
            background-color: #383838;
            border: 2px solid #e6e6e6;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 14px;
                width: 14px;
                /*background-color: black;*/
                border-radius: 18px;
                /*border: 2px solid #fff;*/
                /*padding: 3px;*/
            }
        }

        .close {
            position: absolute;
            top: -6px;
            right: -8px;
            img {
                height: 25px;
                width: 25px;
                /*background-color: black;*/
                //background-color: #dbdbdb;
                background-color: #383838;
                border: 2px solid #e6e6e6;
                border-radius: 25px;
                padding: 2px;
            }
        }
    }

    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>