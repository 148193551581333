<template>
    <div class="scratch-container">
        <div class="area">

            <MainArea ref="main"></MainArea>

            <Bottom
                @selectSprite="selectSprite"
                @deleteSprite="deleteSprite">
            </Bottom>

            <v-overlay
                :absolute="absolute"
                :opacity="opacity"
                :value="overlay"
                :z-index="103"
            >
                <div class="help-list">
                    <div class="help-col">
                        <div class="help-title">引入图形库</div>
                        <div class="help-name">import kgame</div>
                        <div class="help-spacer"></div>
                        <div class="help-spacer"></div>
                        <div class="help-spacer"></div>

                        <div class="help-title">创建/获取角色</div>
                        <div class="help-name">kgame.Actor("角色名称")</div>
                        <div class="help-eg">actor = kgame.Actor("宇航员")</div>
                        <div class="help-spacer"></div>
                        <div class="help-spacer"></div>
                        <div class="help-spacer"></div>

                        <div class="help-title">角色命令</div>
                        <div class="help-name">moveRight()</div>
                        <div class="help-eg">actor.moveRight()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">moveLeft()</div>
                        <div class="help-eg">actor.moveLeft()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">moveUp()</div>
                        <div class="help-eg">actor.moveUp()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">moveDown()</div>
                        <div class="help-eg">actor.moveDown()</div>
                        <div class="help-spacer"></div>
                    </div>
                    <div class="help-col">
                        <div class="help-title">移动类命令</div>
                        <div class="help-name">move(steps)</div>
                        <div class="help-eg">actor.move(10)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">turnLeft(degree)</div>
                        <div class="help-eg">actor.turnLeft(30)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">turnRight(degree)</div>
                        <div class="help-eg">actor.turnRight(30)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">moveTo(x, y)</div>
                        <div class="help-eg">actor.moveTo(100, 100)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">glide(sec, x, y)</div>
                        <div class="help-eg">actor.glide(1, 100, 100)</div>
                        <div class="help-spacer"></div>
                    </div>
                    <div class="help-col">
                        <div class="help-title">外观类命令</div>
                        <div class="help-name">show()</div>
                        <div class="help-eg">actor.show()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">hide()</div>
                        <div class="help-eg">actor.hide()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">say(msg)</div>
                        <div class="help-eg">actor.say('你好')</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">sayFor(sec, msg)</div>
                        <div class="help-eg">actor.sayFor('你好', 1)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">think(msg)</div>
                        <div class="help-eg">actor.think('你在想什么？')</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">thinkFor(sec, msg)</div>
                        <div class="help-eg">actor.thinkFor('你在想什么？', 1)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">nextCostume()</div>
                        <div class="help-eg">actor.nextCostume()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">setCostume(costumeName)</div>
                        <div class="help-eg">actor.setCostume('宇航员')</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">nextBackdrop()</div>
                        <div class="help-eg">actor.nextBackdrop()</div>
                        <div class="help-spacer"></div>
                    </div>
                    <div class="help-col">
                        <div class="help-title">画笔命令</div>
                        <div class="help-name">clear()</div>
                        <div class="help-eg">actor.clear()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">penDown()</div>
                        <div class="help-eg">actor.penDown()</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">penUp()</div>
                        <div class="help-eg">actor.penUp()</div>
                        <div class="help-spacer"></div>
                        <!-- <div class="help-name">setColor(color)</div>
                        <div class="help-eg">actor.setColor('')</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">setHue(hue)</div>
                        <div class="help-eg">actor.setHue('')</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">changeHue(hue)</div>
                        <div class="help-eg">actor.changeHue('')</div>
                        <div class="help-spacer"></div> -->
                        <div class="help-name">setPenSize(size)</div>
                        <div class="help-eg">actor.setPenSize(3)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">changePenSize(size)</div>
                        <div class="help-eg">actor.changePenSize(1)</div>
                        <div class="help-spacer"></div>
                        <div class="help-name">stamp()</div>
                        <div class="help-eg">actor.stamp()</div>
                        <div class="help-spacer"></div>
                    </div>
                </div>
            </v-overlay>
        </div>

        <div id="blockly-code-generator" class="blockly-code-generator" style="display: none"></div>

        <Loading />

    </div>
</template>

<script>
    import MainArea from './children/MainArea';
    import Bottom from './children/Bottom';
    import Loading from '@/views/components/mask/Loading';

    import Ide from '@/libs/ide/ide.js';
    import CoreData from '@/libs/runtime/coreData.js';
    import qs from 'qs'
    import cos from '@/utils/cos.js'
    import "@/libs/index.js";
    import { mapGetters } from 'vuex'

    // common
    import { getIdeType, getCourseInfo, getSectionInfo } from '@/utils/common'
    // web/admin同名，但需要区分的接口
    import { getClassroomData } from '@/api/web'
    // web
    import { createProject, saveProjectCover, sendReleaseMsg } from '@/api/web'
    // admin
    import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'
    
    export default {
        name: 'python-graphic',
        data: function() {
            return {
                isClassroom: false,
                isEditor: false,
                isIde: false,

                type: '',

                absolute: true,
                opacity: 1,
                overlay: false,
            }
        },
        // 不知道为啥没有调用成功？
        // 但是暂时离开页面有提示，先用着，后面排查
        beforeRouteLeave: function(to, from , next){
            let value = window.confirm("确定要离开当前页面吗？");
            if (value == true) {
                // 确定
                next()
            } else {
                // 取消
                next(false)
            }
        },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
                'actorAreaData',
                'curActor',
                'pid',
                'cid',
                'sid',
                'userSectionId',
                'current',
                'userInfo',
                'projInfo',
            ]),
        },
        created() {
        },
        mounted() {
            // 加载userInfo
            this.loadUserInfo();

            // let pid = ''
            // let sid = ''
            // let cid = ''
            // if (this.$route.query.pid) {
            //     pid = this.$route.query.pid == 'undefined' ? '' : this.$route.query.pid
            // }
            // if (this.$route.query.cid) {
            //     cid = this.$route.query.cid
            // }
            // if (this.$route.query.sid) {
            //     sid = this.$route.query.sid
            // }

            // let path = this.$route.path
            // if (path == '/iframe/classroom/python-graphic') {
            //     this.isClassroom = true
            //     this.loadClassroomData(cid, sid)

            // } else if (path == '/iframe/editor/python-graphic') {
            //     this.isEditor = true
            //     this.loadEditorData(cid, sid)
                
            // } else if (path == '/iframe/ide/python-graphic') {
            //     this.isIde = true
            //     this.loadProjectData(pid)
            // }

            // this.type = getIdeType(this.$route.path)
        },
        destroyed() {
        },
        activated() {
        },
        methods: {
            getCourseInfo,
            getSectionInfo,
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
            loadPage(pid) {
                this.loadProjectData(pid)
            },
            // async loadClassroomData(cid, sid) {
            //     this.$store.commit('web/SetCourseId', cid)
            //     this.$store.commit('web/SetSectionId', sid)

            //     let resId = ''
            //     let toolbox = ''
            //     let res = await getClassroomData({ cid, sid })
            //     if (res && res.data) {
            //         if (res.data.resId) {
            //             resId = res.data.resId
            //         }
            //         if (res.data.userResId) {
            //             this.userResId = res.data.userResId
            //         }
            //         if (res.data.toolbox) {
            //             toolbox = res.data.toolbox
            //         }
            //     }

            //     // 加载上课信息
            //     this.getCourseInfo(cid)

            //     // 加载小节信息
            //     this.getSectionInfo(sid)

            //     // 展示课程目标示例
            //     this.$store.commit('web/SetCurrent', 'demo')

            //     // 加载上课数据
            //     this.$store.dispatch('web/LoadClassroomData', {
            //         resId: resId,
            //         userResId: this.userResId,
            //         toolbox: toolbox, 
            //     });
            // },
            // // TODO: 此处userResId的用法？
            // async loadEditorData(cid, sid) {
            //     this.$store.commit('web/SetCourseId', cid)
            //     this.$store.commit('web/SetSectionId', sid)

            //     let res = await getAdminSectionData({ cid, sid })
            //     if (res && res.data) {
            //         this.userResId = res.data.resId
                
            //         this.$store.commit('web/SetProjectId', this.userResId)
            //     }
            //     // 加载作品数据
            //     this.$store.dispatch('web/LoadProjectData', this.userResId)

            //     // 加载小节信息
            //     this.getSectionInfo(sid)

            //     // 展示用户作品
            //     this.$store.commit('web/SetCurrent', 'user')
            // },
            loadProjectData(pid) {
                // 如果为空：不加载数据，直接进入编辑器
                if (pid == '') {
                    this.$store.commit('web/SetLoadDataDone');
                    return
                }

                this.$store.commit('web/SetProjectId', pid)

                // 加载Project信息
                this.$store.dispatch('web/GetProjectInfo', pid)

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'user')

                // 加载作品数据
                // 加载资源文件
                this.$store.dispatch('web/LoadProjectData', pid + '.res')
                // 加载代码文件
                this.loadEditorContent(pid)
            },
            async loadEditorContent(id) {
                if (id != '') {
                    let data = await cos.getObject('project', 'project', id + '.py');
                    if (data && data.Body) {
                        this.$refs.main.setEditorContent(JSON.parse(data.Body));
                    } else {
                        this.$refs.main.setEditorContent('');
                    }
                }
            },
            selectSprite(id) {
                this.$refs.main.selectSprite(id);
            },
            deleteSprite(id) {
                this.$refs.main.deleteSprite(id);
            },
            // 切换舞台
            reloadStage(nav) {
                this.$refs.main.reloadStage(nav);
            },
            handleCmd(data) {
                switch (data.cmd) {
                    case 'save':
                        this.save()
                        break
                    case 'release':
                        this.release()
                        break
                    case 'help':
                        this.help()
                        break
                    case 'switchCode':
                        this.switchCode(data.params)
                        break
                    case 'toggleData':
                        this.toggleData()
                        break
                    case 'toggleShowDemoCode':
                        this.toggleShowDemoCode()
                        break
                }
            },
            // 上传完成后回调：
            uploadDoneCallback(newCover) {
                // 更新数据库，保存新的coverId
                saveProjectCover({
                    id: this.pid,
                    newCover: newCover
                });
            },
            async save() {
                // 校验是否登陆
                if (this.userInfo.roles.length == 0) {
                    this.$refs.loginDlg.openDlg();
                    return;
                }

                if (this.pid != '') {
                    this._save()

                } else {
                    let res = await createProject({
                        name: this.projInfo.name,
                        type: 'G_PYTHON',
                    })
                    if (res && res.data) {
                        let pid = res.data.pid
                        this.$store.commit('web/SetProjectId', pid)

                        this._save()
                    }
                }
            },
            async _save() {
                // 保存截屏
                let cover = window.stageInstance.screenshot();
                // console.log(cover);

                // 想改成直接在前端保存，没成功
                // 好像是base64解码的地方有问题

                // // preg_match('/^(data:\s*image\/(\w+);base64,)/', $cover, $result)
                // let coverData = cover.replace(/^data:image\/(\w*);base64,/, '');
                // // console.log(coverData);
                // let img = window.atob(coverData)
                // console.log(img)
                // await qiniu.uploadFile(img, 'covers', this.uploadDoneCallback)

                await saveProjectCover(qs.stringify({
                    pid: this.pid,
                    cover: cover
                }));

                // 保存项目数据
                let id = ''
                if (this.isClassroom) {
                    id = this.userSectionId
                } else if (this.isIde) {
                    id = this.pid
                }
                // 1.保存图形部分
                // CoreData.saveBlock(this.curActor.id);
                let data = CoreData.getSaveData('user');
                if (id != '' && data != '') {
                    await cos.putObject('project', 'project', id + '.res', JSON.stringify(data));
                }
                // 2.保存代码部分
                let src = this.$refs.main.getEditorContent();
                if (id != '' && src != '') {
                    await cos.putObject('project', 'project', id + '.py', JSON.stringify(src));
                }

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
            },
            async release() {
                this.save()

                await sendReleaseMsg({
                    type: 'project',
                    category: 'scratch',
                    id: this.isClassroom ? this.userSectionId : this.pid,
                    cid: ''
                });
            },
            help() {
                this.overlay = !this.overlay
            },
            switchCode(params) {
                // 更新一下代码区内容
                Ide.workspace2code();
                // 切换代码区显示
                this.$store.commit('web/ShowArea', { name: 'CODE_AREA', isShow: params.showCode });
            },
            toggleData() {
                let isShow = !this.ide.area.isShowDataArea;
                this.$store.commit('web/ShowArea', { name: 'DATA_AREA', isShow: isShow });

                if (isShow && this.dataAreaData.length == 0) {
                    // 展示初始化的数据区变量
                    this.$store.commit('web/InitDataAreaData');
                }
            },
            toggleShowDemoCode() {
                this.$store.commit('web/ToggleShowDemoCode');
            },
        },
        components: {
            MainArea,
            Bottom,
            Loading,
        }
    }
</script>


<style lang="scss">
// 用于隐藏iframe区中的上下滚动条
html, body {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.help-list {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

    .help-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 320px;
        // flex: 1;
        text-align: left;
    }

    .help-title {
        font-size: 17px;
        font-weight: bold;
        color: orange;
        margin-bottom: 10px;
    }
    .help-name {
        font-size: 16px;
        font-weight: bold;
        color: #e94b4b;
    }
    .help-eg {
        font-size: 16px;
        font-weight: bold;
        color: #eee;
        white-space: nowrap;
    }
    .help-spacer {
        width: 100%;
        margin-bottom: 10px;
    }
}

.scratch-container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .area {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  .fullscreen-class-start {
    position: fixed;
    z-index: 2100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: rgba(20, 20, 20, 0.85);
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fefefe;
    .text {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 3px;
      margin-bottom: 30px;
    }
    .qrcode {
      height: 300px;
      width: 300px;
      margin-bottom: 60px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .btn-start {
      font-size: 20px;
      font-weight: bold;
      padding: 15px 30px;
      background-color: #ff912d;
      border-radius: 100px;
      border: 3px solid #efefef;
      margin-bottom: 100px;
      cursor: pointer;
    }
  }

//   // 隐藏积木区横向&纵向滚动条
//   .blocklyScrollbarHandle {
//     opacity: 0;
//   }
}
</style>
